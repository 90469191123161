import React from 'react';
import { Menu, Badge, Avatar, Image, Tooltip, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { HomeOutlined, MessageOutlined, SettingOutlined, ReadOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import store from 'src/core/store';
import UserAvatar from './bits/UserAvatar';
import { useSelector } from 'react-redux';
import { MyUserAvatar } from './bits/UserAvatar';

const NavMenu = ({ project }) => {
    const usermenu =
        <ul>
            {/* <li><Button type="text">User settings</Button></li>
            <li><Button type="text">Help center</Button></li>
            <hr /> */}
            <li><Button type="text" onClick={() => store.dispatch({ type: 'LOGOUT' })}>Logout<LogoutOutlined /></Button></li>
        </ul>;


    let menuEntries = null;

    const location = useLocation();
    const user = useSelector(s => s.auth.user);
    const firstPath = '/' + (location.pathname.split('/')[3] || '');

    if (project) {
        menuEntries = <>
            <Menu.Item key="/" icon={<HomeOutlined />}>
                <Link to={`/p/${project.id}`}>Dashboard</Link>
            </Menu.Item>

            <Menu.Item key="/tickets" icon={<MessageOutlined />}>
                <Link to={`/p/${project.id}/tickets`}>Tickets</Link>
            </Menu.Item>

            {/* <Menu.Item key="/articles" icon={<ReadOutlined />}>
                <Link to={`/p/${project.id}/articles`}>Articles</Link>
            </Menu.Item> */}

            <Menu.Item key="/settings" icon={<SettingOutlined />}>
                <Link to={`/p/${project.id}/settings`}>Settings</Link>
            </Menu.Item>
        </>;
    }

    return (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['/']} selectedKeys={[firstPath]}>
            <div className="menu-divider" style={{ marginBottom: '27px' }} />

            {menuEntries}

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', bottom: 24, left: 24 }}>
                <Badge>
                    <Tooltip placement="rightBottom" title={usermenu}><MyUserAvatar /></Tooltip>
                </Badge>

                <Button size='small' onClick={() => { const b = document.getElementsByTagName("body")[0]; b.setAttribute("data-theme", b.getAttribute("data-theme") !== 'dark' ? "dark" : "light") }}>X</Button>

                <Image width={30} style={{ marginTop: 16, opacity: 0.2, filter: 'grayscale(100%)' }} src="https://gamesupport.gg/favicon.png" preview={false} />
            </div>
        </Menu>
    );
}


export default NavMenu;
