import React from 'react';
import { Button } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { actions } from 'src/reducers';
import store from 'src/core/store';

const MenuToggleButton = () => {
    const extraHidden = useSelector(s => s.dashboard.extraHidden);

    return (
        <Button type="text" style={{ marginLeft: 16 }} onClick={() => store.dispatch({ type: actions.dashboard.SET_EXTRA_HIDDEN, payload: { hidden: !extraHidden } })}>
            {extraHidden ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </Button>
    );
}

export default MenuToggleButton;
