import React from "react";
import { Row, Col, Card, List, Avatar, Tag, Button, Table, Alert } from 'antd';
import { ArrowRightOutlined, CaretUpFilled } from '@ant-design/icons';
import { history } from "src/core";
import { useProject } from "src/core/hooks";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ToggleableExtra from "src/components/bits/ToggleableExtra";
import MenuToggleButton from 'src/components/MenuToggleButton';
import TicketUpdates from "src/components/pages/tickets/TicketUpdates";

const items = [
    { id: 339, name: 'alixers118@gmail.com', title: 'Bug report from alixers118@gmail.com', status: 'open', for: '2 Days' },
    { id: 338, name: 'jamesmnb7777@gmail.com', title: 'Curse of Aros - MMORPG', status: 'open', for: '3 Days' },
    { id: 336, name: 'gdmaster725@gmailm.com', title: 'Bug report from gdmaster725@gmailm.com', status: 'open', for: '3 Days' }
];

const data = [
    { day: 'Mon', value: 50 },
    { day: 'Tue', value: 150 },
    { day: 'Wed', value: 100 },
    { day: 'Thu', value: 250 },
    { day: 'Fri', value: 100 },
    { day: 'Sat', value: 200 },
    { day: 'Sun', value: 150 }
];

const chartConfig = {
    data,
    meta: { day: { min: 0.4, max: 5.5 } },
    areaStyle: {
        fill: 'l(90) 0:#1F86EE 1:#1F86EE00',
        fillOpacity: 1.0
    },
    lineStyle: {
        color: 'red'
    },
    line: {
        color: '#1e86ee'
    },
    textStyle: {
        fill: 'red',
        color: 'green'
    },
    autoFit: true,
    smooth: true,
    isStack: false,
    startOnZero: true,
    isPercent: false,
    height: 220,
    xField: 'day',
    yField: 'value',
    xAxis: { line: null, tickLine: null, label: { formatter: (t) => t[0], style: { fill: '#6D7383' } } },
    yAxis: { tickInterval: 100, label: { style: { fill: '#6D7383' } }, grid: { line: { style: { stroke: '#3F4556', lineDash: [8, 8] } } } },
};

const footer =
    <div style={{ textAlign: 'left', fontSize: 16, display: 'flex', alignItems: 'center', cursor: 'auto' }}>
        <div><span style={{ color: 'white' }}>3</span> tickets waiting on your reply</div>
        <div style={{ position: 'absolute', right: '0' }}><Button>All open tickets</Button></div>
    </div>;

const Dashboard = () => {
    const project = useProject();
    const user = useSelector(s => s.auth.user);

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ padding: '26px 32px', flex: 1 }}>
                <Row style={{ marginTop: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Col flex="auto"><h1 style={{ fontSize: 14, color: '#6D7383', fontWeight: 400 }}>Current project: <span style={{ color: 'white' }}>{project.name}</span></h1></Col>
                    <Col flex="none">
                        {/*<Button type="text"><AppstoreOutlined /></Button>*/}
                        <MenuToggleButton />
                    </Col>
                </Row>

                <Row style={{ marginTop: 32 }} gutter={32}>
                    {/* <Col sm={24} xxl={12}>
                        <Card title="Tickets waiting for you" bodyStyle={{ padding: 0 }} actions={[footer]} >
                            <List
                                bordered
                                dataSource={items}
                                renderItem={item => (
                                    <TicketItem {...item} />
                                )}
                            />
                        </Card>
                    </Col> */}

                    <Col sm={24} xl={24} style={{ marginBottom: 24 }}>
                        <Alert message="Heads up! GameSupport is in Early Access, features might not work as expected — or they might not work at all." type="warning" showIcon closable />
                    </Col>

                    <Col sm={24} xl={12}>
                        <Card title="Welcome to GameSupport! 🎉" headStyle={{ padding: '16px 32px' }}>
                            <Row gutter={32}>
                                <Col span={24}>
                                    <h3>Hi {user.firstName}!</h3>
                                    <p>It's great to have you here - it's time to get some action going.</p>

                                    <p>In the table below, you'll find your project's key information. The ticket email address is also shown - why not <b><a href={`mailto:${project.workspace}@gamesupport.gg`}>try sending an email</a></b>?</p>

                                    <Table dataSource={[{ name: 'Email', value: project.workspace + '@gamesupport.gg' }, { name: 'Name', value: project.name }]}
                                        rowKey="name"
                                        style={{ marginTop: 24, marginBottom: 32 }}
                                        showHeader={false}
                                        tableLayout
                                        columns={[{ title: "name", dataIndex: "name", render: (v) => <b>{v}</b> }, { title: "value", dataIndex: "value" }]}
                                        pagination={false} />

                                    <h3>Have some teammates?</h3>
                                    <p>If you're here with others to tackle customer support, perhaps you'd like to invite them.</p>
                                    <Link className="ant-btn ant-btn-primary" to={`/p/${project.id}/settings/proj/users`}><span style={{ marginTop: 4 }}>✍🏻 Invite my team</span></Link>
                                </Col>
                            </Row>
                        </Card>

                        {false && <>
                            <Card>
                                <Col span={8}>
                                    <TicketStat title="Opened today" quantity="25" quantifier="tickets" />
                                </Col>
                                <Col span={8}>
                                    <TicketStat title="Solved today" quantity="18" quantifier="tickets" />
                                </Col>
                                <Col span={8}>
                                    <TicketStat title="Still unresolved" quantity="6" quantifier="tickets" />
                                </Col>

                                <Col span={8}>
                                    <TicketStat title="Average first response" quantity="38" quantifier="m" />
                                </Col>
                                <Col span={8}>
                                    <DualTicketStat title="Average response time" quantity1="1" quantifier1="h" quantity2="54" quantifier2="m" />
                                </Col>
                                <Col span={8}>
                                    <TicketStat title="Customer satisfaction" quantity="91" quantifier="%" />
                                </Col>

                                <Col span={24}>
                                    <div><span style={{ color: '#8A90A1', fontSize: 16 }}>Weekly ticket volume</span> <span style={{ color: '#FF5630' }}><CaretUpFilled style={{ marginRight: -4 }} /> 28%</span> <span style={{ color: '#6D7383' }}>Since last week</span></div>
                                    <div style={{ marginTop: 22 }}>{/*<Area {...chartConfig} />*/}</div>
                                </Col>
                            </Card>
                        </>}

                    </Col>

                    <Col sm={12} xl={6}>
                        <Card>
                            <img src="https://gamesupport.gg/images/icons/icon_discord.png" style={{ height: 24, marginBottom: 16 }}></img>
                            <h3>Join us on Discord</h3>

                            <p>Here you can chat with other game devs and more like-minded people. Share your experience and ask away!</p>
                            <p style={{ marginBottom: -4 }}>Click the link below to join 👇</p>
                            <Button className="ant-btn ant-btn-link" href="https://discord.gg/tahDqQAKWG" target="_blank">discord.gg/tahDqQAKWG</Button>
                        </Card>
                    </Col>

                    <Col sm={12} xl={6}>
                        <Card>
                            <img src="https://gamesupport.gg/images/icons/icon_mail.svg" style={{ height: 24, marginBottom: 16 }}></img>
                            <h3>Leave your feedback</h3>

                            <p>You can help shape the future of GameSupport by giving your honest feedback. We'd love to hear from you! </p>
                            <p style={{ marginBottom: -4 }}>Submit your thoughts here:</p>
                            <Button className="ant-btn ant-btn-link" href="https://forms.gle/Sx2RcErHEzuc6ANL7" target="_blank">https://forms.gle/Sx2RcErHEzuc6ANL7</Button>
                        </Card>
                    </Col>

                </Row>
            </div>

            <ToggleableExtra style={{ marginTop: 92 }}>
                <TicketUpdates />
            </ToggleableExtra>
        </div>
    );
};



function TicketStat({ title, quantity, quantifier }) {
    return (
        <>
            <h2 style={{ color: '#8A90A1', fontSize: 16, fontWeight: 400 }}>{title}</h2>
            <span style={{ color: 'white', fontSize: 48, fontWeight: 600 }}>{quantity}</span> <span style={{ color: '#6D7383' }}>{quantifier}</span>
        </>
    );
}

function DualTicketStat({ title, quantity1, quantifier1, quantity2, quantifier2 }) {
    return (
        <>
            <h2 style={{ color: '#8A90A1', fontSize: 16, fontWeight: 400 }}>{title}</h2>
            <span style={{ color: 'white', fontSize: 48, fontWeight: 600 }}>{quantity1}</span> <span style={{ color: '#6D7383' }}>{quantifier1}</span>
            <span style={{ color: 'white', fontSize: 48, fontWeight: 600, marginLeft: 16 }}>{quantity2}</span> <span style={{ color: '#6D7383' }}>{quantifier2}</span>
        </>
    );
}

function TicketItem(item) {
    console.warn("useRouterMatch() removed in TicketItem!");
    const match = { url: '' };//useRouteMatch();

    return (
        <List.Item key={item.id} style={{ cursor: 'pointer' }} onClick={() => history.push(match.url + '/tickets/t/' + item.id)}>
            <Avatar style={{ flex: 'none', backgroundColor: item.name.toRGB(0.3), color: 'white' }}>{item.name[0].toUpperCase()}</Avatar>
            <span style={{ flex: 'none', margin: '0 20px', color: '#6D7383' }}>#{item.id}</span>
            <span style={{ flex: 1, color: 'white' }}>{item.title}</span>
            <Tag style={{ margin: '0 24px' }} color="warning">{item.for}</Tag>
            <ArrowRightOutlined style={{ fontSize: 18 }} />
        </List.Item>
    );
}

export default Dashboard;
