import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { history, store } from './core';

import './index.less';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <Routes>
        <Route path="/*" element={<App />}></Route>
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
