import createReactContext from "mini-create-react-context";
import { useContext } from "react";

const ProjectContext = createReactContext(null);
export const ProjectProvider = ProjectContext.Provider;
export const WithProject = ProjectContext.Consumer;

export function withProject(Component) {
    const C = props => (
        <WithProject>
            {project => {
                return <Component {...props} project={project} />
            }}
        </WithProject>
    );

    C.WrappedComponent = Component;
    return C;
}

export function useProject() {
    return useContext(ProjectContext);
}
