import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';

import LoginPage from './Login';
import RegisterPage from './Register';
import InvitedError from './InvitedError';
import EmailConfirmation from './EmailConfirmation';

const Unauthenticated = () => {
    return (
        <Layout>
            <Row align="center">
                <Col span={24} >
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/invite" element={<RegisterPage />} />
                        <Route path="/invite-expired" element={<InvitedError />} />
                        <Route path="/confirm-email" element={<EmailConfirmation />} />

                        <Route path="/*" element={<Navigate replace to="/login" />} />
                    </Routes>
                </Col>
            </Row>
        </Layout>
    );
}

export default Unauthenticated;

