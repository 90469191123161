
import { useSelector } from 'react-redux';

const ToggleableExtra = ({ children, style = { marginTop: 61 } }) => {
    const extraHidden = useSelector(s => s.dashboard.extraHidden);

    return (
        <div className={extraHidden ? "ant-layout-extra closed" : "ant-layout-extra"} style={style}>
            {children}
        </div>
    );
}

export default ToggleableExtra;
