import React from 'react';
import { Col, Row, Alert } from 'antd';
import store from '../../../core/store';
import { register } from '../../../core/api';
import { actions } from '../../../reducers';

import Logo from '../../../images/logo-full.svg';

class InvitedError extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            registering: false,
            error: null,
        };
    }

    submitRegister = values => {
        if (this.state.registering) {
            return;
        }

        this.setState({ registering: true, error: null });

        register(values.email, values.password, values.first_name, values.last_name).then(r => {
            this.setState({ registering: false, error: null });
            store.dispatch({ type: actions.auth.LOGGEDIN, payload: { token: r.data.token } });
        }).catch(r => {
            this.setState({ registering: false, error: r.response.data.error });
        });
    }

    render() {
        const { registering, error } = this.state;

        return (
            <>
                <div style={{ minHeight: '95vh', position: 'relative' }}>

                    <Row justify="center" align="top" style={{ marginTop: '32px' }}>
                        <a href="https://gamesupport.gg/pricing/"><img alt="logo" src={Logo} height="28" /></a>
                    </Row>

                    <Row className="register-module" justify="center" align="middle" style={{ marginTop: 60 }}>
                        <Col style={{ width: '80%', maxWidth: "380px", textAlign: 'center' }}>

                            {error && <Alert style={{ marginBottom: '20px' }} message={error} type="error" showIcon />}

                            <Row justify="center" align="top" style={{ marginBottom: '12px' }}>
                                <img alt="logo" src="https://play-lh.googleusercontent.com/dgih62tcrL5jrvBuXNaoL8EO7n5lWWn3RgSKy-qlJ-sCcLvDYHgVtIhwDW9WrAbzZzU=s180-rw" height="64" />
                            </Row>

                            <h1 style={{ lineHeight: '1.1' }}>This invite has expired</h1>
                            <p style={{ marginBottom: 26, color: '#8A90A1', fontSize: '16px', fontWeight: '400', lineHeight: '1.4' }}>Invite links expire after 7 days for security reasons. Ask your project owner to send you a new invite. Don't be shy!</p>

                            <div style={{ background: '#32394A', padding: '8px 12px', borderRadius: 6, display: 'inline-block' }}>
                                <p style={{ marginBottom: 0, color: '#8A90A1', fontSize: '14px', fontWeight: '400', lineHeight: '1.4' }}>This invite was sent to <span style={{ fontWeight: '500', color: '#fff' }}>johncena@smashmail.com</span></p>
                            </div>
                        </Col>
                    </Row>

                    <div style={{ textAlign: 'center', position: 'absolute', width: '100%', bottom: 6 }}>
                        <span style={{ fontSize: 11, color: '#33394a' }}>Copyright © <script>document.write((new Date).getFullYear())</script>2021&nbsp;GameSupport, a Bitgate platform. All Rights Reserved.</span>
                    </div>

                </div>
            </>
        );
    }

}

export default InvitedError;
