import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { reducers } from '../reducers';
import { createStore, applyMiddleware, combineReducers } from 'redux';

console.log(reducers);

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

const store = createStore(combineReducers(reducers), applyMiddleware(...middleware));

export default store;
