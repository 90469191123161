import React from 'react';
import { connect } from 'react-redux';
import Authenticated from './components/Application';
import Unauthenticated from './components/pages/preauth/PreauthLayout';

function App(props) {
  const { token } = props;

  // If no token is present, render unauthenticated layout
  if (!token || !token.length) {
    return <Unauthenticated />
  }

  return <Authenticated />;
}

export default connect(state => ({
  token: state.auth.token,
}), {})(App);
