
const AVATAR_COLORS = [
    '#1B518D',
    '#19385D',

    '#513287',
    '#35285A',

    '#8C6416',
    '#524121',

    '#28745B',
    '#204943',

    '#8C392E',
    '#522B2D'
];

// eslint-disable-next-line
String.prototype.toRGB = function (alpha = 1.0) {
    let hash = 0;

    if (this.length === 0)
        return AVATAR_COLORS[0];

    for (let i = 0; i < this.length; i++) {
        hash = this.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }

    if (hash < 0) hash = -hash;
    return AVATAR_COLORS[hash % AVATAR_COLORS.length];
}

