import Axios from 'axios';
import store from './store';
import history from './history';

const API_BASE = (process.env.NODE_ENV === 'development' && process.env.REACT_APP_LOCAL_API) ? 'http://localhost:4000/api/v1' :
    'https://api.gamesupport.gg/api/v1';
const API_BASE_ARTEMIS = 'https://artemis.gamesupport.gg/api/v1';

const axiosInstance = Axios.create({});
axiosInstance.interceptors.response.use(axiosResponseIntercept, axiosErrorIntercept);
export { axiosInstance as axios };

export function register(email, password, first_name, last_name, invite) {
    return Axios.post(API_BASE + '/auth/register', { email, password, first_name, last_name, invite });
}

export function login(email, password) {
    return Axios.post(API_BASE + '/auth/login', { email, password });
}

export function get(endpoint, data) {
    const token = store.getState().auth.token;
    return axiosInstance.get(API_BASE + endpoint, { ...data, headers: { Authorization: 'Bearer ' + token } });
}

export function post(endpoint, data) {
    const token = store.getState().auth.token;
    return axiosInstance.post(API_BASE + endpoint, data, { headers: { Authorization: 'Bearer ' + token } });
}

export function put(endpoint, data) {
    const token = store.getState().auth.token;
    return axiosInstance.put(API_BASE + endpoint, data, { headers: { Authorization: 'Bearer ' + token } });
}

export function postArtemis(endpoint, data) {
    const token = store.getState().dashboard.artemisToken;
    return axiosInstance.post(API_BASE_ARTEMIS + endpoint, data, { headers: { Authorization: 'Bearer ' + token } });
}

export function getArtemis(endpoint, data) {
    const token = store.getState().dashboard.artemisToken;
    return axiosInstance.get(API_BASE_ARTEMIS + endpoint, { params: data, headers: { Authorization: 'Bearer ' + token } });
}

export function patch(endpoint, data) {
    const token = store.getState().auth.token;
    return axiosInstance.patch(API_BASE + endpoint, data, { headers: { Authorization: 'Bearer ' + token } });
}

export function deletee(endpoint) {
    const token = store.getState().auth.token;
    return axiosInstance.delete(API_BASE + endpoint, { headers: { Authorization: 'Bearer ' + token } });
}

function axiosResponseIntercept(value) {
    // Optional interception checks here...
    return value;
}

function axiosErrorIntercept(error) {
    console.log(JSON.stringify(error));

    // When 401 unauthorized, redirect the user to the login page.
    if (error && error.response && (error.response.status === 401)) {
        store.dispatch({ type: 'LOGOUT' });
        history.push('/login');
        return Promise.reject(error);
    } else {
        throw error;
    }
}
