
const initialState = {
    all: [],
    activeId: null,
    loading: true
};

const actions = {
    SET_ALL: 'SET_ALL',
    SET_LOADING: 'SET_LOADING',
    ADD_CONVERSATION: 'ADD_CONVERSATION',
    CLEAR_CONVERSATIONS: 'CLEAR_CONVERSATIONS',
    CLOSE_CONVERSATION: 'CLOSE_CONVERSATION',
    UPDATE_TICKETS: 'UPDATE_TICKETS'
};
for (const k in actions) {
    actions[k] = 'PROJECTS_' + actions[k];
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_ALL: {
            const all = action.payload.map(proj => ({
                tickets: [],
                loading_tickets: true,
                conversations: [],
                ...proj,
            }));

            return { ...state, all, loading: false };
        }
        case actions.SET_LOADING: {
            const all = state.all.map(p => {
                if (p.id === action.payload.project_id) {
                    return { ...p, loading_tickets: action.payload.loading_tickets };
                } else {
                    return p;
                }
            })

            return { ...state, all };
        }
        case actions.ADD_CONVERSATION: {
            const { project, ticket, status, customer } = action.payload;

            const all = state.all.map(proj => {
                if (proj.id !== project) {
                    return proj;
                }

                const excludingThis = proj.conversations.filter(c => c.ticket !== ticket);

                return {
                    ...proj,
                    conversations: [{ ticket, status, customer }, ...excludingThis]
                };
            });

            return { ...state, all };
        }
        case actions.CLEAR_CONVERSATIONS: {
            const { projectId } = action.payload;

            const all = state.all.map(proj => {
                if (proj.id !== projectId) {
                    return proj;
                }

                return { ...proj, conversations: [] };
            });

            return { ...state, all };
        }
        case actions.CLOSE_CONVERSATION: {
            const { projectId, ticketId } = action.payload;

            const all = state.all.map(proj => {
                if (proj.id !== projectId) {
                    return proj;
                }

                return { ...proj, conversations: proj.conversations.filter(c => c.ticket !== ticketId) };
            });

            return { ...state, all };
        }
        case actions.UPDATE_TICKETS: {
            const { tickets, project } = action.payload;

            const all = state.all.map(proj => {
                if (proj.id !== project) {
                    return proj;
                }

                const ids = tickets.map(t => t.id);
                const mappedTickets = tickets.map(t => ({ ...t, tag: `${proj.key}#${t.id}` }));
                const excludingThis = proj.tickets.filter(t => ids.indexOf(t.id) === -1);

                let newList = [...excludingThis, ...mappedTickets];

                // Deduplicate
                newList = newList.filter(function (item, pos) {
                    return newList.findIndex(i => i.id === item.id) === pos;
                });

                return {
                    ...proj,
                    loading_tickets: false,
                    tickets: newList,
                };
            });

            return { ...state, all };
        }
        default:
            return state;
    }
};

export { reducer, actions };
