import { Form, Input, Button, Modal, Alert } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from "react";
import { useSelector } from "react-redux";
import { get, put } from "src/core/api";
import store from 'src/core/store';
import { actions } from "src/reducers";

const NewProjectModal = () => {
    const [form] = Form.useForm();

    const loading = false;

    const [submitting, setSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(null);
    const [key, setKey] = useState('GAME');
    const user = useSelector(s => s.auth.user);

    if (!user) {
        return null;
    }

    const imageUrl = '';
    const uploadButton = (
        <div className="upload-block">
            <div className="upload-block-img"></div>
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />} Upload icon<br></br>
                <small>JPG or PNG. Max size of 5mb</small>
            </div>
        </div>
    );

    function submit(values) {
        setSubmitting(true);
        setSubmitMessage(null);

        put('/projects', values).then(r => {
            const project = r.data;
            console.log(project);

            setSubmitting(false);
            setSubmitMessage({ type: 'success', message: 'Your project has been created!' });

            get('/me/state').then(r => {
                store.dispatch({ type: actions.projects.SET_ALL, payload: r.data.projects });
                store.dispatch({ type: actions.auth.SET_USER, payload: r.data.user });
                store.dispatch({ type: actions.dashboard.SET_STATE, payload: r.data });
            });
        }).catch(e => {
            setSubmitting(false);
            setSubmitMessage({ type: 'error', message: 'Failed to create your project.' });
        })
    }

    return (
        <Modal title={null} visible={true} closable={false} footer={null} className="start-wizard">
            <div style={{ textAlign: 'center' }}>
                <h2>Add your first game</h2>
                <p>These settings can be changed later on.</p>
            </div>

            {submitMessage &&
                <Alert style={{ marginBottom: 8 }} type={submitMessage.type} message={submitMessage.message} showIcon />}

            <Form form={form} autoComplete="off" layout="vertical" onFinish={submit} onChange={(...l) => console.log(l)}>
                {/*<Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"

                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>*/}


                <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                    <Input placeholder="Your awesome game" disabled={submitting} />
                </Form.Item>

                <Form.Item label="Subdomain">
                    <Form.Item name="subdomain" noStyle rules={[{ required: true }]} normalize={v => v.toLowerCase().replace(/[_\s\t]/, '-').replace(/[^a-zA-Z0-9-]/, '')}>
                        < Input placeholder="yourgame" suffix=".gamesupport.gg" disabled={submitting} />
                    </Form.Item>
                    <small>Add your custom domain later through settings</small>
                </Form.Item>

                <Form.Item label="Ticket Key">
                    <Form.Item name="key" noStyle rules={[{ required: true }]} normalize={v => v.toUpperCase()}>
                        <Input placeholder="GAME" suffix="#1234" disabled={submitting} maxLength={4} onChange={e => setKey(e.target.value)} />
                    </Form.Item>
                    <small>"Hey {user.firstName}, can you take a look at <b>{key}#1337?</b>"</small>
                </Form.Item>

                <Form.Item>
                    <Button block type="primary" htmlType="submit" loading={submitting}>Continue to dashboard</Button>
                </Form.Item>
            </Form>
        </Modal >
    );
}

export default NewProjectModal;
