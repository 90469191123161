import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { Routes, Route, Navigate, Link } from 'react-router-dom';
import { FormOutlined } from '@ant-design/icons';

import NavMenu from './NavMenu';
import Dashboard from './pages/Dashboard';
import { ProjectProvider } from 'src/core/hooks';
import { get } from 'src/core/api';
import { actions } from 'src/reducers';
import FloatingHelpWidget from './widget/FloatingHelpWidget';
import store from 'src/core/store';
import NewProjectModal from './NewProjectModal';

const { Content, Sider } = Layout;

const Tickets = React.lazy(() => import('./pages/Tickets'));
const Settings = React.lazy(() => import('src/components/pages/settings/Settings'));

const Authenticated = () => {
  // On mount, fetch dashboard state
  useEffect(() => {
    get('/me/state').then(r => {
      store.dispatch({ type: actions.projects.SET_ALL, payload: r.data.projects });
      store.dispatch({ type: actions.auth.SET_USER, payload: r.data.user });
      store.dispatch({ type: actions.dashboard.SET_STATE, payload: r.data });
    });
  }, []);

  const { all: projects, loading } = useSelector(state => state.projects);
  const { user } = useSelector(state => state.auth);

  if (loading || !user) {
    return <SkeletonLayout />;
  }

  return (
    <Routes>
      <Route path="/p/:proj/*" element={projects.length > 0 ? <NewProjectProvider projId={projects[0].id} /> : <p>...</p>} />
      <Route path="/*" element={projects.length > 0 ? <Navigate replace to={`/p/${projects[0].id}`} /> : <SkeletonLayout />} />
    </Routes>
  );
}

const NewProjectProvider = ({ projId, match }) => {
  const [switcherOpen, setSwitcherOpen] = useState(false);

  const project = useSelector(s => s.projects.all.find(p => p.id === parseInt(projId)));
  const projects = useSelector(s => s.projects.all);
  const extraHidden = useSelector(s => s.dashboard.extraHidden);

  if (!project) {
    return projects.length > 0 ? <Navigate to={`/p/${projects[0].id}`} /> : <SkeletonLayout />;
  }

  return (
    <ProjectProvider value={project}>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider collapsed={true}>
          <img alt="logo" src={project.logo_url} className="logo" style={{ marginBottom: 8/*, cursor: 'pointer'*/ }} /*onClick={() => setSwitcherOpen(!switcherOpen)}*/ />
          <NavMenu project={project} />
        </Sider>

        <Layout>
          <Content style={{ margin: '0' }} className={extraHidden ? "extra-closed" : ""}>
            <div className="site-layout-background" style={{ padding: '0 0px', minHeight: 360 }}>
              <ProjectContent match={match} project={project} />
            </div>
          </Content>
        </Layout>

        {switcherOpen && <>
          <div style={{ position: 'fixed', zIndex: 10000, transition: 'all 0.15s ease-in-out', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: '#000000A0', cursor: 'pointer' }}
            onClick={() => setSwitcherOpen(false)} ></div>
          <ProjectSwitcher projects={projects} active={project} onClose={() => setSwitcherOpen(false)} />
        </>}

        <FloatingHelpWidget />
      </Layout>
    </ProjectProvider>
  );
}

function SkeletonLayout() {
  return <Layout style={{ minHeight: "100vh" }}>
    <Sider className="ant-hidden-test" style={{}} collapsed={true}>
      <img alt="logo" src="https://s3.eu-central-1.wasabisys.com/gamesupport-public/default.png" className="logo" style={{ marginBottom: 8, cursor: 'pointer' }} />
      <NavMenu />
    </Sider>

    <Layout>
      <Content style={{ margin: '0' }}>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          Loading...
        </div>
      </Content>

      <FloatingHelpWidget />
      <NewProjectModal />
    </Layout>
  </Layout>;
}

function ProjectSwitcher(props) {
  const { projects, active, onClose } = props;

  return (
    <div style={{ backgroundColor: '#1F2536', zIndex: 10000, transition: 'all 0.15s ease-in-out', width: 308, position: 'absolute', left: 0, top: 0, borderRadius: '0px 8px 8px 8px' }}>
      <div style={{ padding: '25px 24px 0px 15px' }}>
        {projects.map(p => <Link to={`/p/${p.id}/tickets`} onClick={onClose} key={p.id}>
          <Project {...p} active={active && active.id === p.id} />
        </Link>)}
      </div>

      <hr style={{ margin: '16px 0' }} />

      <div style={{ height: 50, borderRadius: 8, alignItems: 'center', display: 'flex', marginBottom: 24, paddingLeft: 19 }}>
        <div style={{ width: 50, height: 50, backgroundColor: '#282E3F', color: '#8A90A1', borderRadius: 8, fontSize: 20, textAlign: 'center', display: 'inline-block', paddingTop: 10 }}>+</div>
        <span style={{ color: '#6D7383', fontSize: 16, marginLeft: 12, flex: 'auto' }}>Add new project</span>
      </div>
    </div>
  );
}

function Project(props) {
  const { logo_url, name, active } = props;

  return (
    <div style={{ height: 50, backgroundColor: active ? '#282E3F' : 'none', borderRadius: 8, alignItems: 'center', display: 'flex', marginBottom: 16 }}>
      <img src={logo_url} alt="logo" style={{ width: 50, height: 50, flex: 'none', borderRadius: 8 }}></img>
      <span style={{ color: active ? 'white' : '#6D7383', fontSize: 16, marginLeft: 12, flex: 'auto' }}>{name}</span>
      <FormOutlined style={{ fontSize: 16, marginRight: 16, color: '#6D7383' }} />
    </div>
  );
}

function ProjectContent(props) {
  const { project } = props;

  return <Routes>
    {/* No suspense for the dashboard, this is one thing we want to immediately load for people */}
    <Route path="/" element={<Dashboard />} />
    <Route path="tickets/*" element={<Suspense fallback={<div />}><Tickets project={project} /></Suspense>} />
    <Route path="/settings/*" element={<Suspense fallback={<div />}><Settings /></Suspense>} />

    {/* Default route handler when none match, redirects to root */}
    {/* <Route>
      {() => { console.log("HM"); return <Navigate to={`/p/${project.id}`}></Navigate> }}
    </Route> */}
  </Routes>;
}

export default Authenticated;
