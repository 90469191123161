import React from 'react';

import { Button, Image, Avatar, Dropdown, Tooltip, Menu } from 'antd';
import { BulbOutlined, CloseOutlined, ArrowRightOutlined } from '@ant-design/icons';

class FloatingHelpWidget extends React.Component {
    render() {
        const helpTooltip = (
            <span>Leave some feedback :)</span>
        );

        const helpWidget = (
            <Menu className="help-widget-frame">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2>How can we help?</h2>
                    <Button type="text"><CloseOutlined /></Button>
                </div>
                <p>Welcome to the GameSupport beta!</p>
                <a className="help-widget-item" href="https://discord.gg/tahDqQAKWG" target="_blank" rel="noreferrer">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Image style={{ padding: '14px 10px' }} src="https://gamesupport.gg/images/icons/icon_discord.png" preview={false} />
                        <div className="text-container">
                            <h3>Join us on Discord</h3>
                            <span>discord.gg/tahDqQAKWG</span>
                        </div>
                    </div>
                    <Button type="text"><ArrowRightOutlined /></Button>
                </a>
                <a className="help-widget-item" href="https://forms.gle/Sx2RcErHEzuc6ANL7" target="_blank" rel="noreferrer">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Image style={{ padding: '13px 10px' }} src="https://gamesupport.gg/images/icons/icon_mail.svg" preview={false} />
                        <div className="text-container">
                            <h3>Bugs or insults?</h3>
                            <span>Leave your feedback here</span>
                        </div>
                    </div>
                    <Button type="text"><ArrowRightOutlined /></Button>
                </a>

                {/* <div className="help-widget-item" style={{ display: 'inherit' }}>
                    <h3 style={{ marginBottom: 8 }}>Leave your feedback</h3>
                    <Input.TextArea rows={5} placeholder='Tell us about your experience...'></Input.TextArea>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 12 }}>
                        <a><CameraOutlined />Add screenshot</a>
                        <Button type="primary" size="small">Send feedback</Button>
                    </div>
                </div> */}
            </Menu>
        );

        return (
            <div className="help-widget-btn">
                <Dropdown overlay={helpWidget} trigger={['click']}>
                    <a className="ant-dropdown-link">
                        <Tooltip placement="topRight" title={helpTooltip}><Avatar icon={<BulbOutlined />} /></Tooltip>
                    </a>
                </Dropdown>
            </div>
        );
    }
}

export default FloatingHelpWidget;
