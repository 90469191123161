import React from 'react';
import { Col, Row, Button, Form, Input, Alert, Checkbox } from 'antd';
import { Link } from 'react-router-dom';

import store from '../../../core/store';
import { login } from '../../../core/api';
import { actions } from '../../../reducers';

import Logo from '../../../images/logo-full.svg';

class LoginPage extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            loggingIn: false,
            error: null,
        };
    }

    submitLogin = values => {
        if (this.state.loggingIn) {
            return;
        }

        this.setState({ loggingIn: true, error: null });

        login(values.email, values.password).then(r => {
            this.setState({ loggingIn: false, error: null });
            store.dispatch({ type: actions.auth.LOGGEDIN, payload: { token: r.data.token } });
        }).catch(r => {
            let error = r.message;
            if (r.response && r.response.data) {
                error = r.response.data;

                if (error.message) {
                    error = error.message;
                }
            }

            this.setState({ loggingIn: false, error });
        });
    }

    render() {
        const { loggingIn, error } = this.state;

        return (
            <>
                <div style={{ minHeight: '95vh', position: 'relative' }}>

                    <Row justify="center" align="top" style={{ marginTop: '32px' }}>
                        <a href="https://gamesupport.gg/pricing/"><img alt="logo" src={Logo} height="28" /></a>
                    </Row>

                    <Row className="login-module" justify="center" align="middle" style={{ marginTop: 60 }}>
                        <Col style={{ width: '80%', maxWidth: "380px" }}>

                            {error && <Alert style={{ marginBottom: '20px' }} message={error} type="error" showIcon />}

                            <h1 style={{ marginBottom: 26 }}>Log in</h1>


                            <Form layout="vertical" ref={this.formRef.current} onFinish={this.submitLogin}>
                                <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <Form.Item name="password" className="input-extra-options" rules={[{ required: true }]}>
                                    <Input type="password" placeholder="Password" />
                                </Form.Item>
                                <div className="extra-options">
                                    <Checkbox style={{ fontSize: '14px', color: '#6D7383', marginBottom: 0 }}>Remember me</Checkbox>
                                    {/* <Link to="#">Forgot password</Link> */}
                                </div>

                                <Button block htmlType="submit" type="primary" loading={loggingIn}>Log In</Button>
                            </Form>

                            {/*<div className="hr-text">
                                <span>or</span>
                            </div>

                            <Button block htmlType="submit" type="secondary"><Image width={16} style={{ margin: '-5px 0 -3px -4px' }} src="https://cdnlogo.com/logos/g/35/google-icon.svg" preview={false} />Sign In with Google</Button>*/}
                        </Col>
                    </Row>

                    <Row justify="center" style={{ marginTop: 14, paddingBottom: 60 }}>
                        <span style={{ color: '#6D7383' }}>Not registered yet? <Link to="/register">Create an account</Link></span>
                    </Row>

                    <div style={{ textAlign: 'center', position: 'absolute', width: '100%', bottom: 6 }}>
                        <span style={{ fontSize: 11, color: '#33394a' }}>Copyright © <script>document.write((new Date).getFullYear())</script>2021&nbsp;GameSupport, a Bitgate platform. All Rights Reserved.</span>
                    </div>

                </div>


            </>
        );
    }

}

export default LoginPage;
