import React from 'react';

import { UsergroupAddOutlined, FileAddOutlined, MessageOutlined } from '@ant-design/icons';
import { get } from 'src/core/api';
import { Spin } from 'antd';
import ReactTimeago from 'react-timeago';
import { Link } from 'react-router-dom';
import { useProject } from 'src/core/hooks';

class TicketUpdates extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, updates: [], timer: null };
    }

    componentDidMount() {
        this.fetch();

        const key = window.setInterval(this.fetch, 10000);
        this.setState({ timer: key });
    }

    componentWillUnmount() {
        const key = this.state.timer;

        if (key) {
            window.clearInterval(key);
        }
    }

    fetch = () => {
        get('/me/updates').then(resp => {
            this.setState({ updates: resp.data, loading: false });
        });
    }

    render() {
        const updates = this.state.updates.length ? this.state.updates.map(update => {
            return <TicketUpdate key={update.uid} {...update} />
        }) : <div style={{ padding: '16px 32px' }} />;
        // ^ div is used to force proper padding

        return (
            <Spin spinning={this.state.loading}>
                <div style={{ padding: '32 0px' }}>
                    <div style={{ marginTop: -92, padding: '27px 32px', position: 'fixed', width: '100%', marginLeft: -1, borderLeft: 'var(--layout-extra-border)', borderBottom: 'var(--layout-extra-border)', background: 'var(--layout-extra-background)' }}>
                        <h2>Ticket updates</h2>
                    </div>

                    <div style={{ paddingBottom: '92px', minHeight: 120 }}>
                        {updates}

                        <p style={{ textAlign: 'center', marginTop: 48, marginBottom: 48, color: '#6d7383', fontSize: '12px' }}>{this.state.loading ? 'Fetching your updates, just a sec...' : 'This is the end...'}</p>
                    </div>
                </div>
            </Spin>
        );
    }
}

function TicketUpdate(props) {
    const projectId = useProject().id;

    const { type, metadata: meta, time } = props;
    const metadata = JSON.parse(meta);

    return (
        <div style={{ padding: '16px 32px', borderBottom: 'var(--layout-extra-border)' }}>
            <div style={{ display: 'inline-block', width: 18, height: 18, fontSize: 16, color: 'white', verticalAlign: 'top' }}>{getIconFor(type)}</div>
            <div style={{ display: 'inline-block', marginLeft: 8, fontSize: 14 }}>
                <div style={{ color: '#B5BBCC' }}>{formatType(type, metadata, projectId)}</div>
                <div style={{ fontSize: 12, color: '#6D7383' }}><ReactTimeago date={time * 1000} /></div>
            </div>
        </div>
    )
}

function getIconFor(type) {
    switch (type) {
        case 'ticket_created':
            return <FileAddOutlined />;
        case 'customer_reply':
            return <MessageOutlined />;
        default:
            return <UsergroupAddOutlined />;
    }
}

function formatType(type, metadata, projectId) {
    switch (type) {
        case 'ticket_created':
            return <Absolutelink to={`/p/${projectId}/tickets/t/${metadata.ticket_id}`} style={{ color: '#b5bbcc' }}>A new ticket, <span style={{ color: 'white' }}>{metadata.key}#{metadata.ticket_id}</span>, has been filed.</Absolutelink>;
        case 'customer_reply':
            return <>A customer has replied to ticket <span style={{ color: 'white' }}>#{metadata.ticket_id}</span></>;
        default:
            return <>A customer has replied to ticket <span style={{ color: 'white' }}>#{metadata.ticket_id}</span></>;
    }
}

const Absolutelink = ({ children, to, ...props }) => {
    return (
        <Link to={'../../../../..' + to} {...props}>{children}</Link>
    );
}


export default TicketUpdates;
