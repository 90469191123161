
const initialState = {
    
};

const actions = {

};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export {reducer, actions};
