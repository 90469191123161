import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Form, Input, Alert, Spin } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import store from '../../../core/store';
import { get, register } from '../../../core/api';
import { actions } from '../../../reducers';

import Logo from '../../../images/logo-full.svg';

const year = (new Date).getFullYear();

const RegisterPage = () => {
    const [registering, setRegistering] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [initialEmail, setInitialEmail] = useState('');
    const [inviteData, setInviteData] = useState(null);

    const [form] = Form.useForm();

    const inv = useQuery().get('inv');

    const submitRegister = values => {
        if (registering) {
            return;
        }

        setRegistering(true);
        setError(null);

        register(values.email, values.password, values.first_name, values.last_name, inviteData ? inv : null).then(r => {
            setRegistering(false);
            setError(null);

            store.dispatch({ type: actions.auth.LOGGEDIN, payload: { token: r.data.token } });
        }).catch(r => {
            setRegistering(false);
            setError(r.error);

            if (r.response && r.response.data) {
                switch (r.response.data) {
                    case 'email in use':
                        setError('An account with that email address already exists.');
                        break;
                    default:
                        setError(r.response.data);
                        break;
                }
            }
        });
    }

    // Fetch the invitation from the API upon load
    useEffect(() => {
        if (inv && inv.length) {
            setLoading(true);

            get('/invite', { params: { k: inv } }).then(r => {
                setInviteData(r.data);

                // Pre-fill the email field as we know the email (most likely)
                form.setFieldsValue({ email: r.data.invite.email });

                setLoading(false);
            }).catch(() => {
                setError('This invite has expired, or is invalid.');
                setLoading(false);
            });
        }
    }, []);

    return (
        <Spin spinning={loading}>
            <div style={{ minHeight: '95vh', position: 'relative' }}>

                <Row justify="center" align="top" style={{ marginTop: '32px' }}>
                    <a href="https://gamesupport.gg/pricing/"><img alt="logo" src={Logo} height="28" /></a>
                </Row>

                <Row className="register-module" justify="center" align="middle" style={{ marginTop: 60 }}>
                    <Col style={{ width: '80%', maxWidth: "380px" }}>

                        {inviteData ?
                            <>
                                <Row justify="center" align="top" style={{ marginBottom: '12px' }}>
                                    <img alt="logo" style={{ borderRadius: 8 }} src={inviteData.project.logo_url} height="64" />
                                </Row>

                                <h1 style={{ lineHeight: '1.1' }}>You've been invited to {inviteData.project.name}!</h1>
                                <p style={{ marginBottom: 26, color: '#8A90A1', fontSize: '16px', fontWeight: '500', lineHeight: '1.3' }}>Complete your details to join the fun.</p>
                            </>
                            :
                            <>
                                <h1>Create your account</h1>
                                <p style={{ marginBottom: 26, color: '#8A90A1', fontSize: '16px', fontWeight: '500', lineHeight: '1.3' }}>Start your Early Access adventure here.</p>
                            </>
                        }

                        {/*<Button block htmlType="submit" type="secondary"><Image width={16} style={{marginTop: -3, marginLeft: -3}} src="https://cdnlogo.com/logos/g/35/google-icon.svg" preview={false}/>Continue with Google</Button>

                            <div className="hr-text">
                                <span>or</span>
                            </div>*/}

                        {error && <Alert style={{ marginBottom: '20px' }} message={error} type="error" showIcon closable onClose={() => setError(false)} />}

                        <Form layout="vertical" form={form} onFinish={submitRegister}>
                            <Form.Item name="first_name" rules={[{ required: true }]} style={{ float: 'left', marginRight: '6%', width: '47%' }}>
                                <Input placeholder="First name" />
                            </Form.Item>

                            <Form.Item name="last_name" rules={[{ required: true }]}>
                                <Input placeholder="Last name" />
                            </Form.Item>

                            <Form.Item initialValue={initialEmail} name="email" rules={[{ required: true, type: 'email' }]}>
                                <Input placeholder="Work email" />
                            </Form.Item>

                            <Form.Item name="password" className="input-extra-options" rules={[{ required: true }]}>
                                <Input type="password" placeholder="Password" />
                            </Form.Item>

                            <div className="extra-options">
                                <p style={{ marginBottom: 0, color: '#6D7383' }}>Choose a password with 8+ characters</p>
                            </div>

                            <div style={{ display: 'flex', background: '#1f2536', borderRadius: 6, padding: '10px 16px 6px 16px', marginBottom: 8 }}>
                                <div style={{ width: '28px', marginTop: 2, marginRight: 12, }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.877 104.608">
                                        <g id="law" transform="translate(-0.003 -0.002)">
                                            <path id="Path_808" data-name="Path 808" d="M72.57,19.81h0a5.853,5.853,0,0,1,.1,8.26l-1.21,1.21,9.75,9.59L56.55,62.64l-9.5-9.84L10.02,89.83a5.862,5.862,0,0,1-8.26-.1h0a5.853,5.853,0,0,1-.1-8.26L38.83,44.3l-7.44-7.73L54.7,12.79l8.35,8.19,1.28-1.28a5.838,5.838,0,0,1,8.24.11Zm50.31,80.08a4.662,4.662,0,0,1-4.59,4.71l-53.01.01a4.712,4.712,0,0,1,0-9.42h5.65a4.734,4.734,0,0,1-2.39-4.13,4.668,4.668,0,0,1,4.59-4.71h37.3a4.655,4.655,0,0,1,4.6,4.7h0a4.747,4.747,0,0,1-2.41,4.14h5.66a4.648,4.648,0,0,1,4.6,4.7ZM53.68,1.4h0a4.654,4.654,0,0,1,.08,6.57l-27.2,27.2a4.654,4.654,0,0,1-6.57-.08h0a4.654,4.654,0,0,1-.08-6.57l27.2-27.2a4.654,4.654,0,0,1,6.57.08ZM92.35,40.06h0a4.654,4.654,0,0,1,.08,6.57l-27.2,27.2a4.654,4.654,0,0,1-6.57-.08h0a4.654,4.654,0,0,1-.08-6.57l27.2-27.2a4.655,4.655,0,0,1,6.57.08Z" fill="#33394a" fill-rule="evenodd" />
                                        </g>
                                    </svg>
                                </div>
                                <p style={{ textAlign: 'left', color: '#6D7383', lineHeight: 1.3, fontSize: '12px', marginBottom: 0 }}>By clicking “Start your free trial” you agree to the <br></br> <a href="https://gamesupport.gg/eula/" target="_blank" rel="noopener">End User License Agreement</a> and our <a href="https://bitgate.com/privacy-policy/" target="_blank" rel="noopener">Privacy Policy</a>.</p>
                            </div>

                            <Button block htmlType="submit" type="primary" loading={registering}>Start your free trial</Button>
                            {/* <Button block htmlType="submit" type="primary" disabled={registering}>Complete your registration</Button> */}
                        </Form>

                    </Col>
                </Row>

                <Row justify="center" style={{ marginTop: 14, paddingBottom: 200 }}>
                    <span style={{ color: '#6D7383' }}>Already have an account? <Link to="/login">Log in</Link></span>
                </Row>

                <div style={{ textAlign: 'center', position: 'absolute', width: '100%', bottom: 6 }}>
                    <Row justify="center">
                        <span style={{ color: '#8a90a1' }}>Trusted by game studios</span>
                    </Row>

                    <Row className="company-logo" justify="center" style={{ marginBottom: 16 }}>
                        <img alt="partner" src={'https://gamesupport.gg/images/logos/xisle-games-logo.png'} />
                        <img alt="partner" src={'https://gamesupport.gg/images/logos/newworld-interactive_logo.png'} />
                        <img alt="partner" src={'https://gamesupport.gg/images/logos/Bitgate_Logo_White.png'} />
                    </Row>

                    <span style={{ fontSize: 11, color: '#33394a' }}>Copyright &copy; {year} GameSupport, a Bitgate platform. All Rights Reserved.</span>
                </div>

            </div>
        </Spin>
    );
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default RegisterPage;
