import { Avatar } from 'antd';
import { useSelector } from 'react-redux';

const UserAvatar = ({ firstName, lastName, name, hash, id, style = {}, ...rest }) => {
    let contents = '';

    if (firstName && firstName.length && lastName && lastName.length) {
        contents = firstName[0].toUpperCase() + lastName[0].toUpperCase();
        hash = hash || (firstName + ' ' + lastName);
    } else if (name && name.length) {
        contents = name[0].toUpperCase();
        hash = name;
    }

    if (id) {
        hash = 'U:' + id;
    }

    return (
        <Avatar {...rest} style={{ backgroundColor: hash.toRGB(), verticalAlign: 'middle', color: 'white', fontSize: '16px', ...style }}>{contents}</Avatar>
    );
}

export function MyUserAvatar(props) {
    const user = useSelector(s => s.auth.user);
    return user ? <UserAvatar id={user.id} firstName={user.firstName} lastName={user.lastName} hash={user.email} {...props} /> : null;
}

export default UserAvatar;
