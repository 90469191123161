import history from "../core/history";

const storedToken = localStorage.getItem('token');

const initialState = {
    token: storedToken,
    user: null,
};

const actions = {
    LOGGEDIN: 'LOGGEDIN',
    LOGOUT: 'LOGOUT',
    SET_USER: 'SET_USER',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.LOGOUT:
            localStorage.removeItem('token');
            history.push('/login');
            return { ...state, token: null };
        case actions.SET_USER:
            return { ...state, user: action.payload };
        case actions.LOGGEDIN:
            const token = action.payload.token;
            history.push('/');
            localStorage.setItem('token', token);
            return { ...state, token };
        default:
            return state;
    }
};

export { reducer, actions };
