const initialState = {
    extraHidden: localStorage.getItem('dashboard_extra_hidden') || false,
    artemisToken: null,
};

const actions = {
    SET_EXTRA_HIDDEN: 'SET_EXTRA_HIDDEN',
    SET_STATE: 'SET_STATE',
};
for (const k in actions) {
    actions[k] = 'DASHBOARD_' + actions[k];
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_EXTRA_HIDDEN:
            localStorage.setItem('dashboard_extra_hidden', action.payload.hidden);
            return { ...state, extraHidden: action.payload.hidden };
        case actions.SET_STATE:
            return { ...state, artemisToken: action.payload.artemis_token };
        default:
            return state;
    }
};

export { reducer, actions };
